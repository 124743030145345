import React from "react";
import '../styles/Whatsapp.css'

const Whatsapp = () => {
    return (
        <div className="whatsapp">
            <box-icon name='whatsapp' type='logo' color='#ffffff' className="whatsapp-icon" ></box-icon>
            <a
                href="https://api.whatsapp.com/send?phone=+919108175661&text=Hello!%20I%20contacted%20from%20your%20website%20'Subramanya%20Cab%20Service'%20to%20enquire%20about%20a%20cab."
                className="whatsapp-link"
            >
                Need Help?
            </a>
        </div>
    );
};

export default Whatsapp