
import React, { useState } from 'react';
import '../styles/Header.css';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setMenuOpen(!menuOpen)
  };
  const handleClose = () => {
    // setAnchorEl(null);
    setMenuOpen(!menuOpen)
  };


  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <header>
        <div className="logo">
          <img src='/images/logo/SCS.png' alt='logo' className='logo-img' />
        </div>

        <nav className='header-nav'>
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#cars">Vehicles</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#places">Places</a></li>
            <li><a href="#contact">Contact Us</a></li>
          </ul>
        </nav>
        <a href="https://api.whatsapp.com/send?phone=+919108175661&text=Hello!%20I%20contacted%20from%20your%20website%20'Subramanya%20Cab%20Service'%20to%20book%20a%20cab." className="book-now">Book Now</a>


        <div className='header-landing-mob'>
        <div className="logo-mob">
          <img src='/images/logo/SCS.png' alt='logo' className='logo-img' />
        </div>
          <nav className='nav-mobile'>

            {!menuOpen && <button className="hamburger-menu" onClick={() => setMenuOpen(!menuOpen)}>
              <span className="hamburger-bar"></span>
              <span className="hamburger-bar"></span>
              <span className="hamburger-bar"></span>
            </button>}

            {menuOpen && <div className="menu">
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem><button className="hamburger-menu mm" onClick={() => setMenuOpen(!menuOpen)}>
                  <CloseIcon fontSize='large' />
                </button></MenuItem>
                <MenuItem><a href="#home">HOME</a></MenuItem>
                <MenuItem><a href="#about">ABOUT US</a></MenuItem>
                <MenuItem> <a href="#contact">CONTACT US</a></MenuItem>

                <MenuItem className='book-now-mob'><a href="https://api.whatsapp.com/send?phone=+919108175661&text=Hello!%20I%20contacted%20from%20your%20website%20'Subramanya%20Cab%20Service'%20to%20book%20a%20cab.">BOOK NOW</a>
                </MenuItem>
              </Menu>
            </div>}



          </nav>
        </div>

       

      </header >
    </>
  );
};

export default Header;
