import React, { useState } from 'react';
import '../styles/HeroSection.css';

const HeroSection = () => {
    const [name, setName] = useState('');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropLocation, setDropLocation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleBookNow = (e) => {
        e.preventDefault(); 
        const whatsappNumber = '919108175661'; 
        const message = `Hello, my name is ${name}. I would like to book a cab from ${pickupLocation} to ${dropLocation}. My phone number is ${phoneNumber}.`;
        const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;

        window.location.href = whatsappURL;
    };

    return (
        <section className='hero-main'>
            <div className='hero-section'>
                <div className='hero-section-container'>
                    <div className='hero-section-left'>
                        <h2>Book a City Taxi to your destination in town</h2>
                        <p>Contact us for exclusive deals and ease of booking.</p>
                    </div>
                    <div className="slider_section">
                        <div className='slider_form'>
                            <h4 className='slider_title'>
                                Get A Taxi Now
                            </h4>
                            <form onSubmit={handleBookNow}>
                                <input
                                    type="text"
                                    placeholder="Your Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Pick Up Location"
                                    value={pickupLocation}
                                    onChange={(e) => setPickupLocation(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Drop Location"
                                    value={dropLocation}
                                    onChange={(e) => setDropLocation(e.target.value)}
                                    required
                                />
                                <div className="btm_input">
                                    <input
                                        type="text"
                                        placeholder="Your Phone Number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                    />
                                    <button type="submit">Book Now</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
