import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';



import Header from './components/Header';
import HeroSection from './components/HeroSection';
// const MyComponent = lazy(() => import('./MyComponent'));
// const Aboutus = lazy(() => import('./components/Aboutus'));
// import Aboutus from './components/Aboutus';
// import CarOptions from './components/CarOptions';

// import Footer from './components/Footer';
import './styles/App.css';
// import Services from './components/Services';
// import WhyChooseUs from './components/WhyChooseUs';
// import PlaceToVisit from './components/PlaceToVisit';
// import VehicleCard from './components/VehicleCard';
// import Testimonials from './components/Testimonials';
// import BookNow from './components/BookNow';
import Whatsapp from './components/whatsapp';
const App = () => {
  const Aboutus = lazy(() => import('./components/Aboutus'));
  const CarOptions= lazy(() => import('./components/CarOptions'));
  const Footer= lazy(() => import('./components/Footer'));
  const Services= lazy(() => import('./components/Services'));
  const WhyChooseUs= lazy(() => import('./components/WhyChooseUs'));
  const PlaceToVisit= lazy(() => import('./components/PlaceToVisit'));
  const VehicleCard= lazy(() => import('./components/VehicleCard'));
  const BookNow= lazy(() => import('./components/BookNow'));
  const Testimonials= lazy(() => import( './components/Testimonials'));
  return (
    <Router>


    
      <Header />
      <div id='home'>
      <HeroSection />
      </div>
      <Whatsapp />

      <Suspense fallback={<div>Loading...</div>}> 
      <div id='about'>
      <Aboutus  />
      </div>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <CarOptions />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <div id='cars'>
      <VehicleCard />
      </div>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <div id='services'>
      <Services />
      </div>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <div id='features'>
      <WhyChooseUs />
      </div>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <div id='places'>
      <PlaceToVisit />
      </div>
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <Testimonials />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}> 
      <BookNow />
      </Suspense>
      {/* <Contact /> */}

      <Suspense fallback={<div>Loading...</div>}> 
      <div id='contact'>
      <Footer/>
      </div>
      </Suspense>

   </Router>
  );
};

export default App;
